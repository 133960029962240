import Link from 'next/link';
import React, { useEffect } from 'react';
import DefaultWidthContainer from '../Layout/Containers/DefaultWidthContainer';
import useDeviceSize from '../util/Hooks/useDeviceSize';
import styles from './pageNotFound.module.scss';

const PageNotFoundComponent = () => {
    const [widthScreen, heightScreen] = useDeviceSize();

    const setStyles = () => {
        if (widthScreen >= 1150) {
            document.getElementById('footer').style.cssText = `
            position: fixed;
            bottom: 0;
            right: 0;
            left: 0;
        `;
        } else {
            document.getElementById('footer').style.cssText = '';
        }
    };

    useEffect(() => {
        setStyles();
    });

    return (
        <section>
            <DefaultWidthContainer extraClassNames={styles.notFoundContainer}>
                <div className={styles.notFoundContent}>
                    <p className={styles.notFoundDescription}>
                        Page not found. We can’t seem to find the page you are looking for.
                        <br />
                        Try going back to the previous page or visit our homepage.
                    </p>
                    <Link href="/" className="button-link" title="Go to homepage">
                        <span>Homepage</span>
                    </Link>
                </div>
            </DefaultWidthContainer>
        </section>
    );
};

export default PageNotFoundComponent;
