import React from 'react';
import Layout from '../components/Layout/Layout';
import PageNotFoundComponent from '../components/PageNotFound/PageNotFoundComponent';

const Custom404 = () => (
    <Layout defaultMetaTitle={'Noria.no - Page not found'}>
        <PageNotFoundComponent />
    </Layout>
);

export default Custom404;
